import React, { useState, useEffect } from "react"
import axios from "axios"
import { Auth } from "aws-amplify";
import { Chip, Divider, FormControl, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from "@mui/material";
import FilterListIcon from '@mui/icons-material/FilterList';
import EditNoteIcon from '@mui/icons-material/EditNote';
import TranslateIcon from '@mui/icons-material/Translate';

import FileTile from "../../FileTile"

const Intervention = ({ facInfo }) => {
  const [fileData, setFileData] = useState("")
  const [testId, setTestId] = useState(facInfo.allTests ? facInfo.allTests[0].testId : null)
  const [lang, setLang] = useState("English");

  useEffect(() => {
    if(facInfo.allTests){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getFileData = async(testId, facId, adminId, std) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/facilitator/"+facId+"/file?adminId="+adminId+"&testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            const fileData = response.data["file"]
            // console.log("File Data: ", fileData)
            const filteredFileData = fileData.allFiles.filter(item => item.std === std)  // change required to make it work for multiple stds and schools
            setFileData(filteredFileData.map(item => ({...item, viewed: fileData.viewedFiles.includes(item.fileId) })))  // marking viewed true for fileIds whose count is already there in db
          }
          catch (error) {
            console.log(error)
          }
        }
  
        const facId = facInfo.facilitator[0].facId;
        const adminId = facInfo.facilitator[0].adminId
        const std = facInfo.facilitator[0].schools[0].classes[0].std
        // console.log("facId and adminId: ", facId, adminId)
        getFileData(testId, facId, adminId, std)
        
      })
      .catch((err) => console.log(err));
    }
    else{
      alert("No Test has been completed yet!\nRedirecting to Dashboard")
      window.location.replace("/");
    }
    
  }, [facInfo, testId]);

  const handleTestIdChange = (e) => {
    setFileData("");
    setTestId(e.target.value);
  };

  const handleLangChange = (e) => {
    setLang(e.target.value);
  };

  return (
    <div className="InterventionContent innerContent">
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Intervention Files</h2>
      {/* <div className="fileTiles">
        {
          fileData && fileData.map(item => {
            return <FileTile key={item.fileId} fileInfo={item} facId={facInfo.facilitator[0].facId} classId={facInfo.facilitator[0].schools[0].classes[0].classId} />
          })
        }
      </div> */}
      <div className="filters">
        <div className="forms">
          <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
            <FilterListIcon fontSize="small"/>
            <span style={{height: "100%"}}>Filters:</span> 
          </h4>
          <FormControl sx={{ m: 1, minWidth: 150}} size="small">
            <InputLabel id="testNameFilterLabel">Test Name</InputLabel>
            <Select
              labelId="testIdFilterLabel"
              id="testIdFilter"
              value={testId ? testId : ""}
              onChange={handleTestIdChange}
              autoWidth
              label="Test Name"
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              {/* getting all unique std values from data */}
              {facInfo.allTests &&
                facInfo.allTests.map(item => item.testName).map((item, i) => {
                return <MenuItem key={i} value={facInfo.allTests[i].testId}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120}} size="small">
            <InputLabel id="langFilterLabel">Language</InputLabel>
            <Select
              labelId="langFilterLabel"
              id="langFilter"
              value={lang}
              onChange={handleLangChange}
              autoWidth
              label="Language"
            >
              <MenuItem value="English">English</MenuItem>
              <MenuItem value="Hindi">Hindi</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="currentFilters">
          <Typography variant="body1" gutterBottom>
            Current Filters: 
          </Typography>
          { facInfo.allTests && 
            <Chip 
              variant="outlined" 
              color="info"  
              // onDelete={handleLangChipDelete} 
              icon={<EditNoteIcon />}
              label={facInfo.allTests.filter(item=> item.testId === testId)[0].testName}
              sx={{maxWidth: 150}} 
            />
          }
          <Chip 
            variant="outlined" 
            color="info"  
            // onDelete={handleLangChipDelete} 
            icon={<TranslateIcon />}
            label={lang}
            // sx={{mr: 1}} 
          />
        </div>
      </div>
      {fileData && 
        <div className="table" style={{marginTop: "1rem"}}>
          <Divider />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center" style={{padding: "12px" }}><h4>Title</h4></TableCell>
                  <TableCell align="center" style={{padding: "12px" }}><h4>Language</h4></TableCell>
                  <TableCell align="center" style={{width: "200px", padding: "12px" }}><h4>Download</h4></TableCell>
                  <TableCell align="center" style={{width: "350px", padding: "12px" }}><h4>Preview</h4></TableCell>
                  <TableCell align="center" style={{width: "100px", padding: "12px" }}><h4>Downloaded/ Watched</h4></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(!fileData.length || !fileData.filter(item => item.lang === (lang === "English" ? "EN" : "HI")).length) && 
                  <TableRow hover>
                    <TableCell align="center" colSpan={5}>No intervention material found</TableCell>
                  </TableRow>
                }
                {lang==="English" ? 
                  fileData.filter(item => item.lang === "EN").map(item => {
                    return <FileTile key={item.fileId} fileInfo={item} testId={testId} facId={facInfo.facilitator[0].facId} classId={facInfo.facilitator[0].schools[0].classes[0].classId} />
                  })
                :
                  fileData.filter(item => item.lang === "HI").map(item => {
                    return <FileTile key={item.fileId} fileInfo={item} testId={testId} facId={facInfo.facilitator[0].facId} classId={facInfo.facilitator[0].schools[0].classes[0].classId} />
                  })
                }
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      }
    </div>
  )
}

export default Intervention