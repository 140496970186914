import React from 'react'
import { Button } from '@mui/material'
import Navbar from './Navbar'

function PrivacyPolicy() {
  
  return (
    <>
      <Navbar header="Privacy Policy"/>
      <div className="mainContent">
        <div className="content">
          <div className="privacyPolicy">
            <div className="privacyPolicyDiv">
              <h3>TARA Privacy Policy and Terms: </h3>
              <p>We value your trust. In order to honour that trust, TARA adheres to ethical standards in gathering, using, and safeguarding any information you provide for automatic assessment of children's reading skills.</p>
              <p>This privacy policy governs your use of the application TARA – The Application (‘TARA App’), <span></span>
                {/* <Button 
                  variant="text" 
                  href="https://play.google.com/store/apps/details?id=com.prosical.taraapp" 
                  target="_blank"
                  sx={{padding: "0", textTransform: "lowercase", textDecoration: "underline", textUnderlinePosition: "under"}} 
                >
                  https://play.google.com/store/apps/details?id=com.prosical.taraapp
                </Button>  */}
                <span></span> (‘Website’) and the other associated applications, products, websites and services(Platform) managed by TARA. Please read this privacy policy (‘Policy’) carefully before using the Application, Website, our services and products, along with the Terms of Use (‘ToU’) provided on the Application and the Website. Your use of the Website, Application, or services in connection with the Application, Website or products (‘Services’), or registrations with us through any modes or usage of any products including through SD cards, tablets or other storage/transmitting device shall signify your acceptance of this Policy and your agreement to be legally bound by the same. If you do not agree with the terms of this Policy, do not use the Website, Application our products or avail any of our Services.
              </p>
              <p>By accessing the TARA website at <span></span> 
                <Button 
                  variant="text" 
                  href="https://www.taralit.com" 
                  target="_blank"
                  sx={{padding: "0", textTransform: "lowercase", textDecoration: "underline", textUnderlinePosition: "under"}} 
                >
                  https://www.taralit.com
                </Button>
                <span>, Android app(“TARA”) at </span>
                <Button 
                  variant="text" 
                  href="https://play.google.com/store/apps/details?id=com.prosical.taraapp" 
                  target="_blank"
                  sx={{padding: "0", textTransform: "lowercase", textDecoration: "underline", textUnderlinePosition: "under"}} 
                >
                  https://play.google.com/store/apps/details?id=com.prosical.taraapp
                </Button>
                , and iOS app on the App store, hereon collectively referred to as the TARA Application, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law.
              </p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>User License : </h3>
              <p>TARA and /or its affiliates own all material and all information (in whatever form or media) provided or communicated to you by or on behalf of TARA. Any content TARA including its trademark will not be used, modified or altered by you in any way.</p>
              <p>Permission is granted to temporarily download one copy of the materials (information or software) on TARA's website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of ownership, and under this license you may not :</p>
              <ul>
                <li>Modify, alter or copy the material in any way.</li>
                <li>Use the material for any commercial purpose, or for any public display. (commercial or non- commercial)</li>
                <li>Attempt to decompile or reverse engineer any software contained on TARA Service website.</li>
                <li>Remove any copyright or other proprietary notations from the materials.</li>
                <li>Transfer the materials to another person or "mirror" the materials on any other server.</li>
                <li>Unauthorised use of the material.</li>
                <li>Distribution, transmission, republication, display or performance of the content and any component thereof.</li>
              </ul>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Disclaimer: </h3>
              <p>The materials on TARA's website are provided on an 'as is' basis. To the fullest extent permitted by law TARA makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchant-ability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights either expressly or impliedly.</p>
              <p>Further, TARA does not warrant, make any representations or liabilities concerning the accuracy, security, reliability, quality, availability or likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Limitations: </h3>
              <p>In no event shall TARA or its affiliates, representatives, suppliers shall not  be held  liable for any direct, indirect, special or punitive losses or expenses or consequential damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on TARA’s website, even if TARA or a TARA authorized representative has been notified orally or in writing of the possibility of such damage. We understand that in some jurisdictions warranties, disclaimers & conditions may apply that cannot be legally excluded, if that affirms with your jurisdiction, then to the extent permitted by law.</p>
              <p>TARA and its affiliates limit their liability for any claims under those warranties or conditions supplying you the Tara material again. You/User’s specifically acknowledge that TARA shall  not be held liable for material or defamatory, offensive or illegal conduct of any Third party & that the risk of harm or damage from the foregoing acts entirely rest with you.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Accuracy of Material: </h3>
              <p>The materials appearing on TARA's website could include technical, typographical, or photographic errors. TARA does not warrant that any of the materials on its website are accurate, complete or current. TARA may make changes to the materials contained on its website at any time without any prior notice. However TARA does not make any commitment to update the materials.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Links: </h3>
              <p>TARA has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by TARA of the site. Use of any such linked website is at the user's own risk.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Modifications: </h3>
              <p>TARA at its sole discretion may revise these terms of service for its website at any time without any notice. By using this website you are agreeing to be bound by such modification or revision. Your continued use of the service shall be taken as your consent and acceptance to such modification.</p>
              <p>This Agreement doesn’t grant third-party rights. Please regularly check our platform for updates to stay informed about updated changes.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Governing Law: </h3>
              <p>Tara shall be entity rendering the Services and accordingly, the Agreement shall be governed by and construed in accordance with the laws of India and you agree and irrevocably submit to the exclusive jurisdiction of the courts at Mumbai, Maharastra, India.</p>
            </div>
            <div className="privacyPolicyDiv">
              <h3>Privacy Policy: </h3>
              <p>Your privacy is important to us.</p>
              <p>It is TARA’s privacy policy to respect your privacy regarding any information we may collect while operating our website or subscribe to our services. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information. We have outlined our privacy policy below.</p>
              <ul>
                <li>We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned.</li>
                <li>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected.</li>
                <li>We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law.</li>
                <li>Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date.</li>
                <li>We will protect personal information by using reasonable security safeguards against loss disclosure, copying, use or modification.</li>
                <li>We will make readily available to customers information about our policies and practices relating to the management of personal information.</li>
                <li>We retain your email and contact information from inquiries for future reference, including phone numbers shared. We track page visits for statistical purposes to enhance our services.</li>
                <li>We will only retain personal information for as long as necessary for the fulfilment of those purposes.</li>
              </ul>
              <p>We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. TARA may change this privacy policy from time to time at TARA’s sole discretion.</p>
              <h4 style={{paddingTop: "0.5rem"}}>1. User (Facilitator/Teacher) Policy: </h4>
              <p>By accepting this Policy, you are also representing that you are eighteen (18) years of age or above(herein after referred as “Major”). In case you are under the age of eighteen (18), then please do not share any Sensitive  Information or Personal Information with us without having your parent or legal guardian(“Guardian") to accept our Policy on your behalf. In the event a Students(“minor”) utilizes the Application / Website / Services, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the legal guardian or parents. TARA will not be responsible for any consequence that arises as a result of misuse of any kind of Our Application or any of Our products or Services that may occur by virtue of any person including a Students registering for the Services/products provided.</p>
              <p>By using the products or Services You warrant that all the data provided by You is accurate and complete and that the Student using the Application has obtained the consent of parent/legal guardian (in case of minors). TARA reserves the right to terminate Your subscription and/or refuse to provide You with access to the products or Services if it is discovered that You are under the age of 18 (eighteen) years and the consent to use the products or Services is not made by Your parent/legal guardian or any information provided by You is inaccurate.</p>
              <p>You acknowledge that TARA does not have the responsibility to ensure that You confirm to the aforesaid eligibility criteria. It shall be Your sole responsibility to ensure that You meet the required criteria . Any persons under the age of 18 (eighteen) should seek the consent of their Guardian before providing any Information about themselves or their parents and other family members on the Application.</p>
              <p>We believe that every user of our Applications / Services / Products / Website(Platform) must be in a position to provide an informed consent prior to providing any Information required for the use of the Applications / Services / Products / Website. By registering with us, or by using the Website, Applications, or Services, you are expressly consenting to our collection, processing, storing, disclosing and handling of your Information asset forth in this Policy now and as amended by us. Processing, your Information in any way, including, but not limited to, collecting, storing, deleting, using, combining, sharing, transferring and disclosing information, all of which activities will take place in India. If you reside outside India your Information will be transferred, processed and stored in accordance with the applicable data protection laws of India.</p>
              <div className="privacyPolicySubDiv">
                <h5 style={{fontSize: "16px"}}>1.1 What information do we collect?</h5>
                <ul>
                  <li><strong>Information you provide to us:</strong> When you sign up for an account on the Application, we collect your email address and password. We will provide you with the means to ensure that your Personal Information is correct and current. If you have filled out a user profile, we will provide an obvious way for you to access and change your profile from our Application. We adopt reasonable security measures to protect your password from being exposed or disclosed to anyone.</li>
                  <li><strong>Information you provide to us about your students:</strong> During student registration, we ask you to provide the following Personally Identifiable Information (PII) of students: Name (initials only), Gender, Class, Age, Division, Roll No and Personal Education Number(PEN). Apart from such information we may require further information such as School Name, School code, Region name, Region code, Shift number in School. We will only use this data internally after removing any PII information. We do not ask for or collect any additional personal information of your students. By providing information to us about your students, you warrant that the student using the Application has obtained the consent of parent/legal guardian (in case of minors).</li>
                  <li><strong>App usage information we collect when you use the Application:</strong> We collect information about how you use the Application, such as the features you use and the time spent on the Application. We may also collect other information from your device(s) or from the Play Store through which you downloaded the TARA app. For example, we may collect information about the device(s) you, and the children accessing our App through your account, use to access the App (e.g., mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, preferred language). We may collect analytics data to help us measure traffic and usage trends for the App and to understand more about the demographics of our users. We use this information to customize the function and appearance of the App based on information related to your account.</li>
                </ul>
              </div>
              <div className="privacyPolicySubDiv">
                <h5 style={{fontSize: "16px"}}>1.2 How do we use the information?</h5>
                  <ul>
                    <li>We use the information we collect to:</li>
                    <ul>
                      <li>Operate, maintain, enhance, and provide all of the features of our Application.</li>
                      <li>Communicate with you about your account and account activity (such as your student’s progress) and to respond to inquiries.</li>
                      <li>Understand and analyze the usage trends, behaviors, and preferences of our users, to improve the way the App works and looks, and to develop new features and functionality.</li>
                    </ul>
                    <li>Aggregate and individual, anonymized and non-anonymized data may periodically be transmitted to external service providers to help us improve the Applications, products and our Services. We will share your information with third parties only in the ways that are described below in this Policy.</li>
                  </ul>
              </div>
              <div className="privacyPolicySubDiv">
                <h5 style={{fontSize: "16px"}}>1.3 How we share or transfer data to others?</h5>
                <ul>
                  <li>We may at times provide aggregate statistics about our customers, traffic patterns, and related site information to reputable third parties, however this information when disclosed will be in an aggregate form and does not contain any of your Personally Identifiable Information that you provide us.</li>
                  <li>However, these third party companies do not have any independent right to share this information. We do not sell, trade or rent your Information to any third party unless we have been expressly authorized by you either in writing or electronically to do so or when we need to for legal reasons.</li>
                </ul>
              </div>
              <div className="privacyPolicySubDiv">
                <h5 style={{fontSize: "16px"}}>1.4 How can you get your data removed? </h5>
                <ul>
                  <li>You can request for deletion of your personal data from our systems by contacting us at prosical.iitb@gmail.com or filling in the <span></span>
                    <Button 
                      variant="text" 
                      href="https://forms.gle/sqYUr4TrjAdRByk58" 
                      target="_blank"
                      sx={{padding: "0", textTransform: "capitalize", textDecoration: "underline", textUnderlinePosition: "under"}} 
                    >
                      TARA User Data Deletion Request form
                    </Button>.
                  </li>
                  <li>Upon receiving a valid data deletion request, we will take reasonable steps to verify your identity and ensure the request is legitimate. Once verified, we will promptly and permanently delete your personal data from our systems, except as required by law or where certain exceptions apply.</li>
                </ul>
              </div>
              <h4 style={{paddingTop: "0.5rem"}}>2. Children’s (Student’s) Privacy Policy: </h4>
              <p>Our App is available for teachers, schools and school districts (collectively referred to as “Schools”) to use as part of the School’s educational curriculum. Our App is designed to minimize the personal data collected and used, and we strive to implement best practices to protect the privacy of all of our users. An adult with a verified email address must download the App and create an account before permitting a child to use the App. The App is intended to be used by children under 18 only post parental consent and with the oversight of a teacher or school for use as part of the school's curriculum. Please alert us at prosical.iitb@gmail.com if you believe we have inadvertently collected information from a child under 18 without consent from a parent or guardian so that we may delete the information as soon as possible.</p>
              <p>The following applies when a child under 18 with parental consent uses the App for school use with a teacher’s oversight:</p>
              <ul>
                <li><strong>What student information do we collect?</strong> When a teacher adds a class's students, we collect the Name (initials only), Gender, Class, Age, Division, Roll No and Personal Education Number(PEN) of a child provided by the teacher. The App may request permission to access your device’s microphone. When a teacher records a student, we collect the audio recording of the child along with details of the story that was used for recording. We associate this information with the teacher's or school’s account information, to help personalize the learning experience and track a child’s progress. We may also collect other information from the device(s) used to download and access the TARA app (for example, the mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, preferred language) to customize the function and appearance of the App. We may also collect analytics data to help us measure traffic and usage trends for the App and to understand more about the demographics of our users. We do not ask for or collect other personal information from the child. We use this data internally after removing any Personally Identifiable Information (PII).</li>
                <li><strong>How do we use this information?</strong> We use it to operate, personalize, maintain, enhance, and provide all of the features of our App. We do not use this information for marketing or advertising purposes.</li>
                <li><strong>Legal Disclosures:</strong> We may disclose information about you in response to a court order, or other governmental request. Without limitation to the foregoing, we reserve the right to disclose such information where we believe in good faith that such disclosure is necessary to: comply with applicable laws, regulations, court orders, government and law enforcement agencies’ requests; protect and defend TARA’s or a third party's rights and property, or the safety of TARA, our users, our employees, or others; or prevent, detect, investigate and take measures against criminal activity, fraud and misuse or unauthorized use of our Platform and/or to enforce our Terms and Conditions or other agreements or policies. To the extent permitted by law, we will attempt to give you prior notice before disclosing your information in response to such a request.</li>
                <li>We utilize your information to enable Platform access, maintain accounts, offer customer support, facilitate purchases, verify user data, and resolve technical issues. This processing is based on consent, legitimate interests, or contract fulfillment between you and us.</li>
                <li><strong>How do we share the child’s information?</strong> The teacher will be able to see information regarding the child's use of the App in connection with the teacher’s class and track children’s progress from their class. Aggregated data at the class level will be shared with school administrators to monitor and track progress. We share the information internally after removing any PII information, and otherwise as described in Section 1.2 & 1.3 above. We do not share the child’s personal information with third parties for their own purposes or for marketing purposes.</li>
                <li><strong>How can the School control the child’s information?</strong> When a teacher creates accounts for students, the teacher can change the child’s user name at any time in the App. The School is responsible for managing App accounts which the School no longer needs for an educational purpose by removing the child from the class roster, deleting the class, or submitting a deletion request when such data is no longer needed for School purposes. Schools should contact us at prosical.iitb@gmail.com to request deletion of personally identifiable information associated with the School’s use of our App.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivacyPolicy