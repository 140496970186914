import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios"
import { AlertTitle, Button, Divider, FormControl, InputLabel, List, ListItem, ListItemText, MenuItem, Select, Snackbar } from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import { Auth } from "aws-amplify";
import UploadIcon from '@mui/icons-material/Upload';
import DescriptionIcon from '@mui/icons-material/Description';

import SampleWorksheet from "../../../data/SampleWorksheet.xlsx"
import SheetCannotBeReadSolution from "../../../data/SheetCannotBeReadSolution.PNG"
import DeleteStudents from "./DeleteStudents";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="standard" {...props} />;
});

const ExcelUpload = ({adminInfo}) => {

  useEffect(() => {
    if(adminInfo.admin.schools.length === 1) setSchool(adminInfo.admin.schools[0].id)
  }, [adminInfo]);

  const [toastOpen, setToastOpen] = useState(false);
  const [toastType, setToastType] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadDisbale, setUploadDisable] = useState(false);
  const [uploadResponse, setUploadResponse] = useState({});
  const [school, setSchool] = useState("");

  function downloadSample(){
    const link = document.createElement('a');
    link.download = "Student-Sample-Worksheet.xlsx";
    link.href = SampleWorksheet;
    link.click();
  }

  const excelUpload = async (file, adminId, schoolId, testId) => {

    Auth.currentSession()
    .then(async (data) => {
      const jwtToken = data.idToken.jwtToken;

      try {
        const uploadResponse =  await axios.post("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/admin/"+adminId+"/student-excel-upload", 
          JSON.stringify({
            "testId": testId,
            "schoolId": schoolId,
            "excelFile": file
          }), 
          {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': jwtToken
          }
        });
        // console.log("Upload Response: ",uploadResponse.data)
        if(uploadResponse.data.status === "success") setToastType(["success", "File Uploded Successfully!"])
        else setToastType(["error", "File not uploaded due to error!"])
        setToastOpen(true)
        setUploadResponse(uploadResponse.data)
        setSelectedFile(null)
        setUploadDisable(false)
      }
      catch (error) {
        console.log(error)
        setToastType(["error", "File not uploaded due to error!"])
        setToastOpen(true)
        setUploadResponse({"status": "error", "message": ["Internal Server Error! - Please try again after sometime, If the issue persists please contact IITB Team."]})
        setUploadDisable(false)
      }
    })
    .catch((err) => console.log(err));
  };

  const handleToastClose = (event, reason) => {
    if (reason === 'clickaway') return
    setToastOpen(false);
  };

  // Helper function for handling file upload
  const handleFileUpload = (e) => {
    setUploadResponse({})
    setToastOpen(true)
    
    if (!school){
      setToastType(["warning", "Please select a school for upload!"])
      return
    }

    if (!selectedFile){
      setToastType(["warning", "Please select a file to upload!"])
      return
    }
    
    if (selectedFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
      setToastType(["warning", "Please select a .xlsx excel file!"])
      return
    }
    
    if(!adminInfo.test){
      setToastType(["warning", "Test has not started yet!"])
      return
    }

    if(adminInfo.test.status!==0){
      setToastType(["warning", "Cannot add students post the Student Addition Phase!"])
      return
    }

    if(Number(adminInfo.admin.schools.filter(item => item.id === school)[0].studentCount)!==0){
      setToastType(["warning", "For addition of new list of students, please delete existing student list by clicking on \"Students\" from the menu on the left and then clicking \"Delete all entries\"."])
      return
    }

    setToastType(["info", "File Processing!"]);

    const adminId = adminInfo.admin.adminId;
    // console.log(adminId, schoolId)

    // console.log(selectedFile);
    const reader = new FileReader();
    reader.readAsDataURL(selectedFile);
    reader.onload = () => {
      excelUpload(reader.result, adminId, school, adminInfo.test.testId)
      setUploadDisable(true)
    }
  }

  const handleSchoolChange = (e) => {
    setSchool(e.target.value);
  };

  return (
    <div className="excelUpload" style={{display: "flex", flexDirection: "column", gap: "1rem"}}>
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Add Students using Excel Upload</h2>
      <MuiAlert severity="info" className="uploadInstructions">
        <AlertTitle><b>Instructions for adding students to your class:</b></AlertTitle>
          <Divider/>
          <List dense={true} sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
            <ListItem disableGutters>
              <ListItemText primary={
                <p>
                  <strong>1. Download the Sample Excel Sheet provided here: </strong>
                  <Button className="downloadSample" size="small" variant="contained" color="info" onClick={downloadSample} startIcon={<DescriptionIcon />}>
                    Download Sample Excel Sheet
                  </Button>
                </p>
              }/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>2. DO NOT edit anything on Row 1 in the sheet.</strong></p>}/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>3. Remove the dummy entries from Row 2 to 7 and update the details of your students in the same sheet.</strong></p>}/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>4. Upload the updated excel sheet by clicking on the "Upload Your Excel File Here" button given below.</strong></p>}/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>5. Click on "Submit". (Note: You cannot change the details of students once added.)</strong></p>}/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>6. If there are any "Upload Errors" that cannot be fixed, please contact admins.</strong></p>}/>
            </ListItem>
            <ListItem disableGutters>
              <ListItemText primary={<p><strong>7. If there are no errors, you will get an "Upload Success" message with the total number of students entries given in the Excel sheet.</strong></p>}/>
            </ListItem>
          </List>
      </MuiAlert>
      <div className="upload">
        <FormControl sx={{ m: 1, minWidth: 160 }} size="small">
          <InputLabel id="schoolFilterLabel">Select School</InputLabel>
          <Select
            labelId="schoolFilterLabel"
            id="schoolFilter"
            value={school}
            onChange={handleSchoolChange}
            autoWidth
            label="Select School"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {/* getting all unique divn values from data */}
            { adminInfo &&
              adminInfo.admin.schools.map((item, i) => {
                return <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
              })
            }
          </Select>
        </FormControl>
        <form className="uploadFile">
          <Button
            variant="text"
            component="label"
            // startIcon={}
            style={{
              textTransform: "capitalize",
              gap: "1rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            <label
              className="form-label"
              htmlFor="customFile"
              style={{ display: "flex", alignItems: "center", gap: "1rem", cursor: "pointer", whiteSpace: "break-spaces" }}
            >
              <UploadIcon />
              {selectedFile ? selectedFile.name : 
                <div>
                  <span>
                    {"Upload your Excel file here. \nBy uploading the Excel file, you agree to our"}
                  </span>
                  <Button 
                    variant="text" 
                    component ={Link}
                    target="_blank"
                    to="/privacy-policy" 
                    sx={{padding: "0", paddingLeft: "0.25rem", fontWeight: "bold", textTransform: "capitalize", textDecoration: "underline", textUnderlinePosition: "under"}}
                  >
                    Terms, Privacy Policy & Cookies Policy.
                  </Button>
                </div>
              }
            </label>
            <input
              hidden
              type="file"
              accept=".xlsx"
              onChange={(e) => {setSelectedFile(e.target.files[0]); e.target.value = ""}}
              className="form-control btn"
              id="customFile"
            />
          </Button>
          <Button
            disabled={uploadDisbale}
            onClick={handleFileUpload}
            variant="contained"
            component="label"
            className="btn"
          >
            Submit
          </Button>
        </form>
      </div>
      <Snackbar open={toastOpen} autoHideDuration={5000} onClose={handleToastClose}>
        <Alert onClose={handleToastClose} severity={toastType[0]} sx={{ width: '100%' }}>
        {toastType[1]}
        </Alert>
      </Snackbar>
      {Object.keys(uploadResponse).length!==0 && 
        <MuiAlert severity={uploadResponse.status} className="uploadStatus">
          <AlertTitle><b>Upload {uploadResponse.status === "success" ? "Success" : "Error" }:</b></AlertTitle>
            <Divider/>
            <List dense={true} sx={{listStyleType: "decimal", listStylePosition: "inside"}}>
              {uploadResponse.message.map((m, i) => {
                if(m.includes("—")){
                  let splitM = m.split("—")
                  return (
                    <ListItem key={i} disableGutters>
                      <ListItemText primary={<p><strong>{splitM[0]}</strong> — {splitM[1]}</p>}/>
                    </ListItem>
                  )
                }
                else if(m.includes("[Screenshot]")){
                  let splitM = m.split("[Screenshot]")
                  return (
                    <ListItem key={i} disableGutters>
                      <ListItemText primary={
                        <>
                          <p><strong>{splitM[0]}</strong></p>
                          <img src={SheetCannotBeReadSolution} alt="Sheet Cannot be Read Solution"/>
                        </>
                      }/>
                    </ListItem>
                  )
                }
                else if(m.includes("Delete all entries")){
                  return (
                    <ListItem key={i} disableGutters>
                      <ListItemText primary={
                        <>
                          <p><strong>{m}</strong></p>
                          {school && <DeleteStudents adminInfo={adminInfo} schoolId={school}/>}
                        </>
                      }/>
                    </ListItem>
                  )
                }
                else{
                  return (
                    <ListItem key={i} disableGutters>
                      <ListItemText primary={<p><strong>{m}</strong></p>}/>
                    </ListItem>
                  )
                }
              })}
            </List>
        </MuiAlert>
      }
    </div>
  );
};

export default ExcelUpload;
