import React, { useState, useEffect } from "react";
import { Button, Chip, Divider, FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from "@mui/material";
import axios from "axios"
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import FilterListIcon from '@mui/icons-material/FilterList';
import MapIcon from '@mui/icons-material/Map';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import PinIcon from '@mui/icons-material/Pin';

import Table from "../../TableComponent";

const Region = ({superAdminInfo}) => {
  const [regionData, setRegionData] = useState([])
  
  useEffect(() => {
    if(superAdminInfo.test){
      Auth.currentSession()
      .then((data) => {
        const jwtToken = data.idToken.jwtToken;
        
        const getRegionData = async(testId,superAdminId) => {
          try {
            const response =  await axios.get("https://zk1128nq2f.execute-api.ap-south-1.amazonaws.com/alpha/superAdmin/"+superAdminId+"/region?testId="+testId, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': jwtToken
                }
            });
            // console.log("Region Data: ",response.data)
            const regionData = response.data["region"].map(item => ({
              ...item, 
              "enRecPercent": item["enRecCount"] ? Math.round(item["enRecCount"]*100/item["studentCount"]*100)/100 : 0, 
              "hiRecPercent": item["hiRecCount"] ? Math.round(item["hiRecCount"]*100/item["studentCount"]*100)/100 : 0,
              "enViewPercent": item["enViewCount"] ? Math.round(item["enViewCount"]*100/item["enFileCount"]*100)/100 : 0,
              "hiViewPercent": item["hiViewCount"] ? Math.round(item["hiViewCount"]*100/item["hiFileCount"]*100)/100 : 0
            }))
            setRegionData(regionData)
            // console.log(regionData)
          }
          catch (error) {
              console.log(error)
          }
        }
        getRegionData(superAdminInfo.test ? superAdminInfo.test.testId : null, superAdminInfo.superAdmin.superAdminId)
        
      })
      .catch((err) => console.log(err));
    }
    else alert("Test has not started yet")
    
  }, [superAdminInfo]);
  
  const columnsEn = [
    {
      key: "region",
      headerName: "Region",
      width: 80
    },
    { 
      key: "schoolCount", 
      headerName: "Number of Schools", 
      width: 80 
    },
    { 
      key: "studentCount", 
      headerName: "Students Count", 
      width: 80 
    },
    // {
    //   key: "enRecCount",
    //   headerName: "Students Tested Count - English",
    //   width: 80
    // },
    {
      key: "enRecPercent",
      headerName: "Students Tested - English (%)",
      width: 120,
      percent: true
    }
  ];

  // if(superAdminInfo?.test?.status === 3) columnsEn.push({
  //   key: "enViewPercent",
  //   headerName: "Intervention Material Viewed - English (%)",
  //   width: 80,
  //   percent: true
  // })

  const columnsHi = [
    {
      key: "region",
      headerName: "Region",
      width: 80
    },
    { 
      key: "schoolCount", 
      headerName: "Number of Schools", 
      width: 80 
    },
    { 
      key: "studentCount", 
      headerName: "Students Count", 
      width: 80 
    },
    // {
    //   key: "hiRecCount",
    //   headerName: "Students Tested Count - Hindi",
    //   width: 80
    // },
    {
      key: "hiRecPercent",
      headerName: "Students Tested - Hindi (%)",
      width: 120,
      percent: true
    }
  ];

  // if(superAdminInfo?.test?.status === 3) columnsHi.push({
  //   key: "hiViewPercent",
  //   headerName: "Intervention Material Viewed - Hindi (%)",
  //   width: 80,
  //   percent: true
  // })

  const headers = [
    { label: "Region", key: "region" },
    { label: "Number of Schools", key: "schoolCount" },
    { label: "Students Count", key: "studentCount" },
    { label: "Students Tested Count - English", key: "enRecCount" },
    { label: "Students Tested - English (%)", key: "enRecPercent" },
    { label: "Students Tested Count - Hindi", key: "hiRecCount" },
    { label: "Students Tested - Hindi (%)", key: "hiRecPercent" },
    // { label: "Intervention Material File Count - English", key: "enFileCount" },
    // { label: "Intervention Material View Count - English", key: "enViewCount" },
    // { label: "Intervention Material Viewed - English (%)", key: "enViewPercent" },
    // { label: "Intervention Material File Count - Hindi", key: "hiFileCount" },
    // { label: "Intervention Material View Count - Hindi", key: "hiViewCount" },
    // { label: "Intervention Material Viewed - Hindi (%)", key: "hiViewPercent" }
  ];

  const orderByEn = ["enRecPercent", "enViewPercent"];
  const orderByHi = ["hiRecPercent", "hiViewPercent"];
  const [region, setRegion] = useState("");
  const [std, setStd] = useState("All");
  const [tabValue, setTabValue] = useState(0);
  
  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };
  
  const handleRegionChipDelete = () => {
    setRegion("");
  };

  const handleStdChange = (e) => {
    setStd(e.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  var filteredRegionData
  if(std==="All"){
    filteredRegionData = [...new Set(regionData.map(item => item.region))].map(region => {
                            let stdRegions= regionData.filter(item => item.region === region)
                            let counts = stdRegions.reduce((acc, item) => {
                              return {
                                "schoolCount": acc.schoolCount <= item.schoolCount ? item.schoolCount : acc.schoolCount,
                                "studentCount": acc.studentCount + item.studentCount,
                                "enRecCount": acc.enRecCount + item.enRecCount,
                                "hiRecCount": acc.hiRecCount + item.hiRecCount,
                                "enFileCount": acc.enFileCount + item.enFileCount,
                                "enViewCount": acc.enViewCount + item.enViewCount,
                                "hiViewCount": acc.hiViewCount + item.hiViewCount
                              }
                            }, {"schoolCount": 0, "studentCount": 0, "enRecCount": 0, "hiRecCount": 0, "enFileCount": 0, "enViewCount": 0, "hiViewCount": 0 })
                            return {
                              ...stdRegions[0],
                              ...counts,
                              "enRecPercent": counts["enRecCount"] ? Math.round(counts["enRecCount"]*100/counts["studentCount"]*100)/100 : 0, 
                              "hiRecPercent": counts["hiRecCount"] ? Math.round(counts["hiRecCount"]*100/counts["studentCount"]*100)/100 : 0,
                              "enViewPercent": counts["enViewCount"] ? Math.round(counts["enViewCount"]*100/counts["enFileCount"]*100)/100 : 0,
                              "hiViewPercent": counts["hiViewCount"] ? Math.round(counts["hiViewCount"]*100/counts["hiFileCount"]*100)/100 : 0
                            }
                          })
  }
  else filteredRegionData = [...regionData.filter(item => item.std === std)]
  // var filteredRegionData
  // if(region===""){
  //   filteredRegionData = filteredStdRegionData
  // }
  /* hard coded values for ziets */
  if(region==="ZIET BHUBANESWAR"){
    let ziet1 = ["BHUBANESWAR", "GUWAHATI", "KOLKATA", "RANCHI", "SILCHAR", "TINSUKIA"]
    filteredRegionData = [...filteredRegionData.filter(item => ziet1.includes(item.region))]
  }
  else if(region==="ZIET CHANDIGARH"){
    let ziet2 = ["CHANDIGARH", "DEHRADUN", "DELHI", "GURUGRAM", "JAMMU"]
    filteredRegionData = [...filteredRegionData.filter(item => ziet2.includes(item.region))]
  }
  else if(region==="ZIET GWALIOR"){
    let ziet3 = ["AGRA", "BHOPAL", "JABALPUR", "LUCKNOW", "VARANASI"]
    filteredRegionData = [...filteredRegionData.filter(item => ziet3.includes(item.region))]
  }
  else if(region==="ZIET MUMBAI"){
    let ziet4 = ["AHMEDABAD", "JAIPUR", "MUMBAI", "PATNA", "RAIPUR"]
    filteredRegionData = [...filteredRegionData.filter(item => ziet4.includes(item.region))]
  }
  else if(region==="ZIET MYSORE"){
    let ziet5 = ["BENGALURU", "CHENNAI", "ERNAKULAM", "HYDERABAD"]
    filteredRegionData = [...filteredRegionData.filter(item => ziet5.includes(item.region))]
  }
  /* hard coded values for ziets */
  else if(region!==""){
    filteredRegionData = [...filteredRegionData.filter(item => item.region === region)]
  }
  // console.log(regionData)
  // console.log(filteredRegionData)

  return (
    <div className="regionContent innerContent">
      <h2 style={{textAlign: "center", color: "#0288D1", borderRadius: "15px", border: "1px solid"}}>Region Data </h2>
      <div className="filters">
        <div className="forms">
          <h4 style={{display: "flex", justifyContent: "center", alignItems: "center", gap: "0.25rem"}}>
            <FilterListIcon fontSize="small"/>
            <span style={{height: "100%"}}>Filters:</span> 
          </h4>
          <FormControl sx={{ m: 1, minWidth: 80}} size="small">
            <InputLabel id="stdFilterLabel">Class</InputLabel>
            <Select
              labelId="stdFilterLabel"
              id="stdFilter"
              value={std}
              onChange={handleStdChange}
              autoWidth
              label="Class"
            >
              <MenuItem value="All">
                All
              </MenuItem>
              {/* getting all unique std values from data */}
              {regionData &&
                [...new Set(regionData.map(item => item.std))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
                })
              }
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 150}} size="small">
            <InputLabel id="regionFilterLabel">Region</InputLabel>
            <Select
              labelId="regionFilterLabel"
              id="regionFilter"
              value={region}
              onChange={handleRegionChange}
              autoWidth
              label="Region"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* getting all unique region values from data */}
              {[...new Set(regionData.map(item => item.region))].sort().map((item, i) => {
                return <MenuItem key={i} value={item}>{item}</MenuItem>
              })}
              {/* hard coded values for ziets */}
              {superAdminInfo.superAdmin.superAdminId === 24 &&
                [
                  <MenuItem key={1} value="ZIET BHUBANESWAR">ZIET BHUBANESWAR</MenuItem>,
                  <MenuItem key={2} value="ZIET CHANDIGARH">ZIET CHANDIGARH</MenuItem>,
                  <MenuItem key={3} value="ZIET GWALIOR">ZIET GWALIOR</MenuItem>,
                  <MenuItem key={4} value="ZIET MUMBAI">ZIET MUMBAI</MenuItem>,
                  <MenuItem key={5} value="ZIET MYSORE">ZIET MYSORE</MenuItem>
                ]
              }
              {/* hard coded values for ziets */}
            </Select>
          </FormControl>
        </div>
          <div className="currentFilters">
            <Typography variant="body1" gutterBottom>
              Current Filters: 
            </Typography>
            <Chip 
              variant="outlined" 
              color="info"  
              // onDelete={handleStdChipDelete} 
              icon={<PinIcon />}
              label={std}
            />
            {region &&
              <Chip 
                variant="outlined" 
                color="info"  
                onDelete={handleRegionChipDelete} 
                icon={<MapIcon />}
                label={region}
                sx={{mr: 1}} 
              />
            }
          </div>
      </div>
      <div className="tabs" style={{display: "flex"}}>
        <Tabs value={tabValue} onChange={handleTabChange} sx={{minHeight: "30px", width: "60vw"}}>
          <Tab label="English" sx={{fontSize: "inherit", minHeight: "30px"}}/>
          <Tab label="Hindi" sx={{fontSize: "inherit", minHeight: "30px"}}/>
        </Tabs>
        <Button variant="contained" color="info" startIcon={<FileDownloadIcon />} sx={{ marginLeft: "auto", placeSelf: "start" }}>
          <CSVLink data={filteredRegionData} headers={headers} filename={(std !== "All" ? ("Class-" + std + "_" ) : "") + (region ? region : "All") + "-RegionData.csv"}>
            Download as CSV
          </CSVLink>
        </Button>
      </div>
      <Divider />
      <div className="table">
        {tabValue===0 && 
          <Table
            columns={columnsEn}
            tableData={filteredRegionData}
            orderBy={orderByEn} 
          />
        }
        {tabValue===1 && 
          <Table
            columns={columnsHi}
            tableData={filteredRegionData}
            orderBy={orderByHi} 
          />
        }
      </div>
    </div>
  );
};

export default Region;
