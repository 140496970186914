import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import "../App.css";

import { AppBar, Button, Chip, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import TARAHeader from "../data/tara-header-navbar.png";

const Navbar = ({signOut, user, header}) => {
  const [chartWidth, setChartWidth] = useState(0)

  const handleSiderbarDisplayClick = (e) => {
    const sidebarElement = ReactDOM.findDOMNode(document.querySelector(".mainContent .sidebarContent"))
    const chartsElement = ReactDOM.findDOMNode(document.querySelector(".mainContent .charts"))
    if(window.screen.width>1100 && chartsElement && !chartWidth) setChartWidth(chartsElement.clientWidth)

    if(sidebarElement.style.display === "none"){
      if(window.screen.width>1100 && chartsElement) chartsElement.style.width = chartWidth+"px"
      sidebarElement.style.display = "flex"
    }
    else{
      if(window.screen.width>1100 && chartsElement) chartsElement.style.width = "unset"
      sidebarElement.style.display = "none"
    } 
  };

  return (
    <AppBar color="inherit" position="static" className="navbar">
      <Toolbar>
        {user && 
          <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleSiderbarDisplayClick}
          >
            <MenuIcon />
          </IconButton>
        }
        <div className="navHeader">
          <IconButton component={Link} to="/" disableFocusRipple disableRipple sx={{padding: 0}}>
            <img alt="TARA-Header" height="28px" src={TARAHeader}/>
          </IconButton>
        </div>
        {user 
          ?
            <div style={{ display: "flex", justifyContent: "flex-end", flexGrow: 1, gap: "0.5rem" }}>
              <Chip color="info" label={"Hi, "+user.attributes.email} variant="outlined" />
              <Button onClick={signOut} color="info" variant="contained">
                Sign Out
              </Button>
            </div>
          :
            <div style={{paddingLeft: "1rem"}}>
              <h3>{header}</h3>
            </div>
        }
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
